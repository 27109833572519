import "./styles.scss";

import React from "react";

const TopGrid = () => {
  return (
    <section className="chatbot-top-grid">
      <div className="row align-items-center">
        <div className="col-xl-4 offset-lg-4">
          <div className="chatbot-top-grid__inner">
            <p>
              Wykorzystanie chatbota AI w komunikacji marki to nowa i lepsza
              jakość obsługi klienta.
            </p>
            <p>
              Jeśli Twoja infolinia pęka w szwach, otrzymujesz zbyt wiele
              powtarzalnych wiadomości, a komunikacja Twojej firmy z klientami
              nie jest atrakcyjna — to rozwiązanie jest dla Ciebie.
            </p>
          </div>
        </div>
        <div className="col-xl-4 chatbot-top-grid__image__wrapper">
          <div className="chatbot-top-grid__image">
            <img
              className="img-fluid"
              src={require("../../../assets/images/chatbot/chatbot-top-bg.png")}
              alt=""
            />
            <img
              className="img-fluid overlay"
              src={require("../../../assets/images/chatbot/chatbot-top-image.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopGrid;

import "./styles.scss";

import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const Percentage = () => {
	const [progress_visible_1, setProgressVisible1] = useState(false);

	return (
		<section className="chatbot-percentage">
			<div className="row">
				{/* <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3"> */}
				<div className="col-xl-8 offset-xl-4">
					<h3>Czy wiesz, że…? </h3>
					<div className="progress__wrapper">
						<div className="progress__item progress__item--center">
							<VisibilitySensor
								offset={600}
								onChange={(e) =>
									!!e ? setProgressVisible1(true) : null
								}
							>
								{({ isVisible }) => {
									const percentage =
										isVisible || progress_visible_1
											? 82
											: 0;
									return (
										<CircularProgressbar
											value={percentage}
											text={`${percentage}%`}
											styles={buildStyles({
												pathTransitionDuration: 1.5,
											})}
										/>
									);
								}}
							</VisibilitySensor>
							<div className="progress__item__text ">
								<p>
									Aż 82% konsumentów twierdzi, że uzyskanie
									natychmiastowej odpowiedzi jest ważne. przy
									kontaktowaniu się z firmą.
								</p>
								<small>
									(https://www.chatbot.com/blog/chatbot-statistics/)
								</small>
							</div>
						</div>
						<div className="progress__item progress__item--center">
							<VisibilitySensor>
								{({ isVisible }) => {
									const percentage =
										isVisible || progress_visible_1
											? 74
											: 0;
									return (
										<CircularProgressbar
											value={percentage}
											text={`${percentage}%`}
											styles={buildStyles({
												pathTransitionDuration: 1.5,
											})}
										/>
									);
								}}
							</VisibilitySensor>
							<div className="progress__item__text">
								<p>
									Aż 74% internautów woli używać chatbota AI
									do szukania odpowiedzi na proste pytania.
								</p>
								<small>
									(https://www.chatbot.com/blog/chatbot-statistics/)
								</small>
							</div>
						</div>
						<div className="progress__item progress__item--center">
							<VisibilitySensor>
								{({ isVisible }) => {
									const percentage =
										isVisible || progress_visible_1
											? 87
											: 0;
									return (
										<CircularProgressbar
											value={percentage}
											text={`${percentage}%`}
											styles={buildStyles({
												pathTransitionDuration: 1.5,
											})}
										/>
									);
								}}
							</VisibilitySensor>
							<div className="progress__item__text">
								<p>
									Aż 87% konsumentów ocenia pozytywnie swoją
									interakcję z chatbotami AI.
								</p>
								<small>
									(https://bloggingwizard.com/chatbot-statistics/)
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Percentage;

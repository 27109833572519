import "./styles.scss";

import React, { useState, useEffect } from "react";
import scrollToElement from "scroll-to-element";

const BanerParallax = () => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			const section = document.getElementsByClassName(
				"single-offer-parallax"
			)[0];

			if (section) {
				setScroll(
					section.offsetTop - section.offsetHeight - window.scrollY
				);
			}
		});
	}, []);

	return (
		<section
			className="single-offer-parallax chatbot-ai"
			style={{ backgroundPositionX: scroll }}
		>
			<div className="row">
				<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
					<div className="row">
						<div className="col-lg-11">
							<p>
								Stworzymy chatbota AI dopasowanego do potrzeb
								Twojej firmy, zapewniając niezawodną i
								spersonalizowaną obsługę opartą na interakcji z
								klientem w czasie rzeczywistym.
							</p>
							<button
								className="btn btn-line btn-bold"
								onClick={() =>
									scrollToElement("#kontakt", {
										duration: 500,
										offset: 0,
									})
								}
							>
								Zamawiam bezpłatną konsultację
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BanerParallax;

import "./styles.scss";

import React from "react";

const Possibilities = () => {
  const data = [
    {
      image: require("../../../assets/images/chatbot/possibilities-icon-1.svg"),
      header: "Automatyzacja procesów",
      description:
        "Chatbot AI automatyzuje rutynowe zadania, takie jak odpowiadanie na często zadawane pytania, przetwarzanie zamówień i rezerwacji, czy zarządzanie harmonogramami. ",
    },
    {
      image: require("../../../assets/images/chatbot/possibilities-icon-2.svg"),
      header: "Personalizacja odpowiedzi",
      description:
        "Chatbot AI analizuje historię interakcji i preferencje każdego klienta, aby dostarczać spersonalizowane odpowiedzi. ",
    },
    {
      image: require("../../../assets/images/chatbot/possibilities-icon-3.svg"),
      header: "Dostępność 24/7 ",
      description:
        "Bez względu na godzinę chatbot AI udziela natychmiastowej pomocy Twoim klientom. ",
    },
    {
      image: require("../../../assets/images/chatbot/possibilities-icon-4.svg"),
      header: "Skalowalność",
      description:
        "Chatbot AI może jednocześnie obsłużyć nieograniczoną liczbę klientów. ",
    },
    {
      image: require("../../../assets/images/chatbot/possibilities-icon-5.svg"),
      header: "Integracja z systemami CRM",
      description:
        "Integracja chatbota AI z istniejącymi systemami CRM pozwala na zbieranie i analizowanie danych klientów w czasie rzeczywistym. ",
    },
  ];

  const socials = [
    require("../../../assets/images/chatbot/social-fb.svg"),
    require("../../../assets/images/chatbot/social-messenger.svg"),
    require("../../../assets/images/chatbot/social-whatsapp.svg"),
    require("../../../assets/images/chatbot/social-instagram.svg"),
    require("../../../assets/images/chatbot/social-grid.svg"),
  ];

  return (
    <section className="chatbot-possibilities">
      <div className="row">
        <div className="col-xl-7 offset-lg-4">
          <h3 className="pink">Chatbot AI – poznaj jego możliwości!</h3>
          <div className="chatbot-possibilities__grid">
            {data?.map((item, index) => (
              <div className="chatbot-possibilities__item" key={index}>
                <img src={item?.image} alt="" />
                <div className="chatbot-possibilities__item-inner">
                  <div>
                    <strong>{item?.header}</strong>
                    <p>{item?.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <h3>Platformy, na których działa nasz chatbot AI</h3>
          <p>
            Oferujemy wdrożenie chatbotów AI na platformy społecznościowe takie
            jak Facebook, Messenger, WhatsApp, Instagram oraz na firmowe strony
            internetowe. Wykorzystanie chatbotów AI w różnych kanałach
            komunikacji zapewnia spójną i zintegrowaną obsługę klienta.
          </p>
          <div className="chatbot-possibilities__socials">
            {socials?.map((item, index) => (
              <img key={index} src={item} alt="" />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Possibilities;

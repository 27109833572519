import "./styles.scss";

import axios from "axios";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import ShowMore from "react-show-more";
import React, { useState } from "react";

import { Check, Input } from "../../../components";

const Form = ({ locales }) => {
  const { register, errors, handleSubmit, triggerValidation } = useForm({
    mode: "onChange",
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data, e) => {
    setLoading(true);
    setTimeout(() => {
      axios
        .post("https://adream-mailing.herokuapp.com/chatbot-ai-when", data)
        .then((res) => {
          e.target.reset();
          console.log("Wysłane..");
          if (typeof window != "undefined") {
            navigate("/chatbot-ai-dziekujemy-za-kontakt");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(`Błąd wysyłania.. ${error}`);
        });
    }, 1500);
  };

  return (
    <section className="chatbot-form">
      <div className="row">
        <div className="col-xl-8 offset-xl-4">
          <h2>
            Sprawdź jak chatbot AI mógłby
            <br /> wyglądać w Twojej firmie
          </h2>
          <h4>
            Wypełnij formularz, a my
            <br /> skontaktujemy się z Tobą.
          </h4>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  name="name"
                  label="Imię"
                  register={register}
                  validation={{
                    required: "Wymagane",
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  name="lastname"
                  label="Nazwisko"
                  register={register}
                  validation={{
                    required: "Wymagane",
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="email"
                  name="email"
                  label="Adres e-mail"
                  register={register}
                  validation={{
                    required: "Wymagane",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                      message: "Błędny adres e-mail",
                    },
                  }}
                  errors={errors}
                />
              </div>
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-box btn-submit"
                  onClick={() => {
                    triggerValidation();
                  }}
                >
                  {isLoading ? "wysyłanie..." : "wyślij"}
                </button>
              </div>
            </div>
            <div className="acceptance-col">
              <Check
                register={register}
                name="term1"
                size="sm"
                color="white"
                validation={{ required: locales.required }}
                errors={errors}
                label={
                  <ShowMore
                    lines={1}
                    more="więcej"
                    less="mniej"
                    anchorClass="show-more"
                  >
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                    When Sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a
                    (Administrator danych) w celu udzielenia mi dodatkowych
                    informacji handlowych z zakresu oferty Agencji. Podanie
                    danych jest dobrowolne i umożliwia uzyskanie informacji
                    handlowej.
                  </ShowMore>
                }
              />
              <Check
                register={register}
                name="term2"
                size="sm"
                color="white"
                validation={{ required: locales.required }}
                errors={errors}
                label={
                  <ShowMore
                    lines={1}
                    more="więcej"
                    less="mniej"
                    anchorClass="show-more"
                  >
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                    WHEN Sp. z o.o. z siedzibą w Krakowie, ul. Chopina 18/3a,
                    KRS: 0000683438, REGON: 367571758 (Administrator danych) w
                    celu udzielenia mi dodatkowych informacji handlowych z
                    zakresu oferty When sp. z o.o. Podanie danych jest
                    dobrowolne i umożliwia uzyskanie informacji handlowej.
                  </ShowMore>
                }
              />
              <Check
                register={register}
                name="term3"
                size="sm"
                color="white"
                errors={errors}
                label={
                  <ShowMore
                    lines={1}
                    more="więcej"
                    less="mniej"
                    anchorClass="show-more"
                  >
                    Wyrażam zgodę na udostępnienie moich danych osobowych przez
                    When sp. z o. o., z siedzibą w Krakowie, ul. Chopina 18/3a,
                    KRS: 0000683438 jako administratora moich danych osobowych,
                    podmiotowi: Agencja Interaktywna ADream z siedzibą w
                    Krakowie, ul. Chopina 18/3a, KRS: 0000517132 (kolejnemu
                    Administratorowi danych), w celu udzielenia mi dodatkowych
                    informacji handlowych z zakresu oferty świadczonej przez
                    Agencję Interaktywną ADream. <br />
                    Podanie danych jest dobrowolne i umożliwia uzyskanie
                    informacji handlowej.
                  </ShowMore>
                }
              />
            </div>
          </form>
        </div>
      </div>
      <img
        className="img-fluid"
        src={require("../../../assets/images/chatbot/chatbot-form-robot.png")}
        alt=""
      />
    </section>
  );
};

export default Form;

import "./styles.scss";

import React from "react";

const Process = () => {
  return (
    <section className="chatbot-process">
      <img
        className="chatbot-process__image chatbot-process__image--1"
        src={require("../../../assets/images/chatbot/chatbot-robot-1.png")}
      />
      <h2>
        Jak przebiega proces
        <br /> wdrożenia chatbota AI?
      </h2>
      <div className="chatbot-process__progress">
        <img
          className="img-fluid"
          src={require("../../../assets/images/chatbot/process-steps.svg")}
        />
      </div>
      <img
        className="chatbot-process__image chatbot-process__image--2"
        src={require("../../../assets/images/chatbot/chatbot-robot-2.png")}
      />
    </section>
  );
};

export default Process;

import React from "react";
import { Link } from "gatsby";

import locales from "../constants";
import { Layout, ContactFormDark } from "../components";

import {
  TopGrid,
  BanerParallax,
  Possibilities,
  ParallaxListing,
  Process,
  Percentage,
  Form,
  CaseStudy,
} from "../page_components/chatbot-ai";

const TestyAbPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Chatbot AI w komunikacji marki",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Chatbot AI
          <br /> <span>w komunikacji marki</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#analityka">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li className="current">
                <a href="#">Chatbot AI w komunikacji marki</a>
              </li>
              <li>
                <Link to="/konfiguracja-i-wdrozenie-google-analytics-4/">
                  Konfiguracja i wdrożenie Google Analytics 4
                </Link>
              </li>
              <li>
                <Link to="/wdrozenie-analityki/">Wdrożenie analityki</Link>
              </li>
              <li>
                <Link to="/testy-ab-google-optimize/">Testy A/B</Link>
              </li>
              <li>
                <Link to="/analiza-strony-internetowej-przed-startem-kampanii-ppc/">
                  Analiza strony internetowej przed startem kampanii PPC
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="single-offer-sections single-offer-sections--category-5">
        <TopGrid />
        <BanerParallax />
        <Possibilities />
        <ParallaxListing />
        <Process />
        <Percentage />
        <Form locales={locales["pl"]} />
        <CaseStudy />

        <section className="single-offer-section-contact" id="kontakt">
          <div className="form-wrapper">
            <h2 className="single-offer-section-contact__title mb-40">
              Skontaktuj się z nami! <br />
              Wypełnij <span>formularz</span>
            </h2>
            <ContactFormDark locales={locales["pl"]} />
          </div>
          <img
            className="img-fluid single-offer-section-contact__hand"
            src={require("../assets/images/home-contact-hand-right.png")}
            alt=""
          />
        </section>
      </div>
    </Layout>
  );
};
export default TestyAbPage;

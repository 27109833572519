import "./styles.scss";

import React from "react";
import ReactPlayer from "react-player";

const CaseStudy = () => {
	return (
		<section className="chatbot-case-study">
			<div className="row">
				<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
					<h3>
						Case study ze współpracy
						<br /> z Garden of Lights
					</h3>

					<h4>O kliencie</h4>
					<p>
						Garden of Lights to firma specjalizująca się w
						projektowaniu i realizacji kreatywnych instalacji
						świetlnych, która w 2024 roku do marca prowadziła
						dziesięć parków w dziesięciu różnych miastach. Tworzone
						przez nich wystawy wprowadzają w magiczny świat bajek,
						które opowiadane są za pomocą światła i muzyki.
					</p>

					<h4>Wyzwanie</h4>
					<p>
						Wzrost zainteresowania wystawami Garden of Lights
						zmusiło zespół do poszukiwania nowych rozwiązań w dziale
						obsługi klienta. Wzmożony ruch na stronie internetowej,
						stale przeciążona infolinia i niekończąca się ilość
						powtarzalnych wiadomości doprowadziły do długiego czasu
						oczekiwania na odpowiedź i tym samym, do frustracji
						klientów. Aby zwiększyć efektywność komunikacji marki,
						potrzebne było narzędzie, które zautomatyzuje i
						przyśpieszy proces obsługi klienta.
					</p>

					<h4>Efekty</h4>
					<p>
						<strong>Wdrożenie chatbota AI zapewniło:</strong>
					</p>
					<ul>
						<li>całodobową obsługę klienta,</li>
						<li>
							odciążenie infolinii poprzez przekierowanie
							użytkowników na zautomatyzowaną rozmowę z chatbotem
							AI,
						</li>
						<li>
							szybką i efektywną pomoc klientom bez względu na
							ilość zapytań w danym czasie, spersonalizowaną
							komunikację z klientem,
						</li>
						<li>
							obranie określonej “roli” przez chatbota AI w
							zależności od tematyki wystawy
						</li>
					</ul>

					<div className="row">
						<div className="col-md-6">
							<ReactPlayer
								url={require("../../../assets/video/chatbot-ai-1.mp4")}
								width="100%"
								height="100%"
								playing={true}
								muted={true}
								playsinline={true}
								loop={true}
							/>
						</div>

						<div className="col-md-6">
							<ReactPlayer
								url={require("../../../assets/video/chatbot-ai-2.mp4")}
								width="100%"
								height="100%"
								playing={true}
								muted={true}
								playsinline={true}
								loop={true}
							/>
						</div>
					</div>
					<br />

					<h3>Podsumowanie</h3>
					<p>
						Liczba klientów korzystających z chatbota AI na stronach
						Garden of Lights stale rosła. Uwzględniając wszystkie
						parki, w podanych okresach chatbot AI obsłużył tysiące
						użytkowników, co przekładało się na ogromny wzrost
						wydajności i efektywności działu obsługi klienta oraz
						decyzje zakupowe samych klientów.
					</p>
				</div>
			</div>
		</section>
	);
};

export default CaseStudy;

import "./styles.scss";

import React, { useState, useEffect } from "react";
import scrollToElement from "scroll-to-element";

const ParallaxListing = () => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			const section =
				document.getElementsByClassName("parallax-listing")[0];

			if (section) {
				setScroll(
					section.offsetTop - section.offsetHeight - window.scrollY
				);
			}
		});
	}, []);

	const listing = [
		"Szybka i efektywna obsługa klienta",
		"Satysfakcja klientów",
		"Wzrost wyników sprzedażowych",
		"Spersonalizowane oferty i promocje",
		"Zwiększenie konkurencyjności marki",
		"Redukcja kosztów operacyjnych ",
	];

	return (
		<section
			className="parallax-listing"
			style={{ backgroundPositionX: scroll }}
		>
			<div className="row">
				<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
					<h3>Jeden chatbot AI, wiele korzyści</h3>
					<ul>
						{listing?.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default ParallaxListing;
